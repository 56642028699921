/* stylelint-disable */
.lineage_tasks_container {
  width: 100%;
  height: 100%;
  background: white;
  margin-top: 25px;
  padding: 10px;
  z-index: 0;
}

.inner_container {
  width: 100%;
  height: 100%;
  padding: 10;
  z-index: 2;
  position: relative;
}

.categories_name {
  display: flex;
  align-items: center;
  gap: 20px;
}

.background_line1 {
  position: absolute;
  top: 0;
  left: calc(25%);
  z-index: 1;
  width: 1px;
  height: 100%;
  border-right: 1px solid rgba(128, 128, 128, 0.25);
}

.background_line2 {
  position: absolute;
  top: 0;
  left: calc(50%);
  z-index: 1;
  width: 1px;
  height: 100%;
  border-right: 1px solid rgba(128, 128, 128, 0.25);
}

.background_line3 {
  position: absolute;
  top: 0;
  left: calc(75%);
  z-index: 1;
  width: 1px;
  height: 100%;
  border-right: 1px solid rgba(128, 128, 128, 0.25);
}

.react-flow__attribution {
  display: none;
}
.legend_container {
  position: absolute;
  right: 8px;
  top: 5px;
  background-color: #fdfdfd;
  padding: 10px;
  height: 340px;
  width: 144px;
  display: flex;
  gap: 14px;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.18) -6px 5px 13px;
}

.legend_container .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
