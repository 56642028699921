/* stylelint-disable */

.main_container {
  display: flex;
  gap: 30px;
}

.left_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 50%;
}

.settings_container {
  width: 100%;
  height: auto;
  background: #dadada;
  padding-bottom: 20px;
}

.password_container {
  width: 100%;
  height: auto;
  background: #dadada;
  padding-bottom: 20px;
}

.right_container {
  width: 50%;
  height: 30rem;
  background: #dadada;
}
