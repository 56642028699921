/* stylelint-disable */

.workbook_main_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  h3 {
    padding-bottom: 10px;
  }
}

.workbook_chart_container {
  background-color: #fff;
  padding: 20px;
  width: 80%;
}

.workbook_chart_container .buttons_container {
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    gap: 20px;
  }
  .cds--popover-container {
    width: 25%;
  }
}

.tree_node {
  background-color: #dde1e6;
  width: 100%;
  height: 100%;
  z-index: 0;

  .node_type {
    width: 100%;
    padding: 10px;
    color: #fff;
    text-align: center;
  }
  .node_details {
    padding: 6px;
    display: flex;
    gap: 6px;
  }
  h6 {
    color: #000;
    font-weight: 600;
  }
  p {
    font-size: 12px;
  }
}

.dropdown {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 170px;
  z-index: 1;

  ul {
    padding-block: 10px;
    list-style-type: none;
    li {
      padding: 12px;
      display: flex;
      width: 100%;
      cursor: pointer;

      span {
        margin-right: 16px;
      }
    }
    li:hover {
      background-color: #e8e8e8;
    }

    hr {
      width: 80%;
      margin: auto;
    }
  }
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 7999;
  pointer-events: all;
}

.workbookDrawerModal {
  position: absolute;
  top: calc(-2rem);
  right: calc(-2rem);
  bottom: calc(-2rem);
  width: 25rem;
  background-color: #fff;
  z-index: 8000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
