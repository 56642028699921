/* stylelint-disable */

.dashboard_main {
  width: 100%;
  height: auto;
  background: #fff;
  padding: 20px;
}

.cds--tab-content {
  padding: 0;
}

.results_main {
  width: 100%;
  height: auto;
  background: #fff;
  padding: 20px;
}

.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart_grid_container {
  padding: 20px 0;
}

.dashboard_top_card {
  background: rgb(251, 249, 249);
  height: 22rem;
  padding: 20px;
}

.table_card {
  width: 100%;
  height: auto;
  background: rgba(218, 217, 217, 0.829);
  padding-left: 1%;
}

@media screen and (max-width: 1100px) {
  .chart_grid_container {
    flex-direction: column;
    gap: 30px;
  }
}
