/* stylelint-disable */

.data-resource-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.data-resource-card {
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #faf9f6;
  gap: "20px";
  width: calc(33.33% - 14px);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 9px 10px;
}

@media screen and (max-width: 1200px) {
  .data-resource-card {
    width: calc(50% - 14px);
  }
}

@media screen and (max-width: 700px) {
  .data-resource-card {
    width: 100%;
  }
}

.data-resource-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0 0 14px;
}

.data-resource-card-body-row {
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 0 0 20px 15px;
}
